import { Controller } from '@hotwired/stimulus';
import copy from 'copy-to-clipboard';
import { Tooltip } from 'bootstrap';

export default class extends Controller {
  static targets = ['text', 'btn'];

  copyToClipboard() {
    copy(this.textTarget.innerText.trim());

    const originalTitle = this.btnTarget.getAttribute('data-bs-original-title');
    this.btnTarget.setAttribute('data-bs-original-title', 'Copied!');

    // finds tooltip instance and initialize with show()
    const tooltip = Tooltip.getOrCreateInstance(this.btnTarget);
    tooltip.show();

    // Puts back the original title for next hover
    this.btnTarget.setAttribute('data-bs-original-title', originalTitle);
  }
}
