import { Controller } from '@hotwired/stimulus';
import toggleElement from '../utils/toggle-element';

export default class extends Controller {
  static values = {
    current: String,
    target: String,
    visible: String
  };

  currentValueChanged() {
    if (!this.targetValue) {
      return;
    }

    document.querySelectorAll(this.targetValue).forEach((element) => {
      toggleElement(element, this.currentValue === (this.visibleValue || 'true'));
    });
  }

  updateValue(event) {
    this.currentValue = event.target.value;
  }
}
