import { Controller } from '@hotwired/stimulus';
import toggleElement from '../utils/toggle-element';

export default class extends Controller {
  static targets = ['formContainer', 'newDocuments', 'addDocumentBtn'];
  static values = {
    formPrefix: String
  };

  cancelAddingDocument(event) {
    // show the "add document button"
    toggleElement(this.addDocumentBtnTarget, true);
    // remove new document form from html
    event.target.closest('.new-document-form').remove();
  }

  addInvalid(element) {
    element.classList.add('is-invalid');
    const msg = document.createElement('div');
    msg.classList.add('invalid-feedback');
    msg.innerHTML = 'Must not be left blank';
    element.after(msg);
  }

  submitAddingDocument(event) {
    const formElements = event.target.closest('.form-elements');
    const newDocumentForm = event.target.closest('.new-document-form');
    const nameInput = formElements.querySelector('.document-name');
    const fileInput = formElements.querySelector('.document-file');

    // removes invalid feedback messages
    nameInput.classList.remove('is-invalid');
    fileInput.classList.remove('is-invalid');
    formElements.querySelectorAll('.invalid-feedback').forEach((element) => {
      element.remove();
    });

    // validates all fields
    if (!nameInput.value) {
      this.addInvalid(nameInput);
    }

    if (!fileInput.value) {
      this.addInvalid(fileInput);
    }

    if (!fileInput.value || !nameInput.value) {
      return;
    }

    // shows the "add document button"
    toggleElement(this.addDocumentBtnTarget, true);

    // shows the "add document button"
    formElements.classList.add('d-none');

    // add the preview document element
    newDocumentForm.querySelector('.added-document-preview').innerHTML =
      '<div class="border mb-2 quote-document">' +
      '<span class=" d-flex justify-content-between align-items-center quote-attachment p-2 text-dark">' +
      '<span>' +
      `<i class="fa-regular fa-file-pdf me-3 pdf-icon"></i> ${nameInput.value}` +
      '</span>' +
      '<i class="fa-solid fa-circle-xmark cursor-pointer" data-action="click->edit-documents#removeDocument" data-id="DUMMY"> </i>' +
      '</span>' +
      '</div>';
  }

  addDocument() {
    // Create form html
    const documentForm = document.createElement('div');
    documentForm.innerHTML =
      '<div class="new-document-form">' +
      '<div class="added-document-preview"></div>' +
      '<div class="form-elements">' +
      '<div class="mb-3">' +
      `<input type="text" name="${this.formPrefixValue}[documents_attributes][][name]" class="form-control document-name" placeholder="Document name">` +
      '</div>' +
      '<div class="mb-3">' +
      `<input class="form-control document-file" type="file" id="formFile" name="${this.formPrefixValue}[documents_attributes][][file]">` +
      '</div>' +
      '<div class="text-end">' +
      '<button type="button" class="btn btn-outline-secondary me-1" data-action="click->edit-documents#cancelAddingDocument">Cancel</button>' +
      '<button type="button" class="btn btn-primary" data-action="click->edit-documents#submitAddingDocument">OK</button>' +
      '</div>' +
      '</div>' +
      '</div>';

    // append form to container
    this.newDocumentsTarget.append(documentForm);

    // hide the "add document button"
    toggleElement(this.addDocumentBtnTarget, false);
  }

  removeDocument(event) {
    const newDocumentForm = event.target.closest('.new-document-form');

    // if it was just added before submitting
    if (newDocumentForm) {
      newDocumentForm.remove();
    } else {
      toggleElement(event.target.closest('.quote-document'), false);

      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = `${this.formPrefixValue}[remove_documents][]`;
      input.value = event.target.dataset.id;
      this.formContainerTarget.append(input);
    }
  }
}
