import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['quoteContainer'];
  static values = {
    selected: String
  };

  selectedValueChanged() {
    const selectedQuoteElement = document.querySelector('#selected_quote_id');

    if (!selectedQuoteElement) {
      return;
    }

    selectedQuoteElement.value = this.selectedValue;

    document.querySelectorAll('.quote-container').forEach((element) => {
      element.classList.remove('selected');

      if (element.dataset.id === this.selectedValue) {
        if (element.dataset.scheme === 'true') {
          document.querySelector('#submit-quote-btn').disabled = false;
          document.querySelector('#select-quote-with-confirmation').classList.add('d-none');
          document.querySelector('#submit-quote-btn').classList.remove('d-none');
        } else {
          document.querySelector('#submit-quote-btn').disabled = true;
          document.querySelector('#select-quote-with-confirmation').classList.remove('d-none');
          document.querySelector('#submit-quote-btn').classList.add('d-none');
        }
        element.classList.add('selected');
      }
    });
  }

  clickQuote(event) {
    const quoteId = event.currentTarget.dataset.id;
    this.element.querySelector(`#quote_id_${quoteId}`).checked = true;
    this.selectedValue = quoteId;
  }

  updateSelected(event) {
    this.selectedValue = event.target.value;
  }
}
