import { Controller } from '@hotwired/stimulus';
import Quill from 'quill';

export default class extends Controller {
  static targets = [
    'text',
    'richText',
    'submitBtn',
    'emptyMsg',
    'toolbar',
    'attachments',
    'attachmentsList'
  ];

  reset() {
    this.textTarget.value = '';
    this.richTextTarget.querySelector('.ql-editor').innerHTML = '';
    this.submitBtnTarget.disabled = false;
    this.attachmentsTarget.innerHTML = '';
    this.attachmentsListTarget.innerHTML = '';
  }

  connect() {
    this.richTextTarget.insertAdjacentHTML('afterbegin', this.textTarget.value);

    const editor = new Quill(this.richTextTarget, {
      theme: 'snow',
      modules: {
        toolbar: this.toolbarTarget
      }
    });

    editor.on('text-change', () => {
      const editorElement = this.richTextTarget.querySelector('.ql-editor');
      this.textTarget.value = editorElement.innerHTML;
    });
  }

  addAttachment() {
    const el = document.createElement('input');
    el.type = 'file';
    el.name = 'comment[attachments][]';
    el.dataset.action = 'change->new-comment#fileAdded';
    el.dataset.id = parseInt(Math.random() * 100000, 10);
    this.attachmentsTarget.appendChild(el);
    el.click();
  }

  fileAdded(event) {
    const html =
      `<div class="attachment-name" data-id="${event.target.dataset.id}">` +
      '<i class="fa-regular fa-file me-2"></i>' +
      event.target.value.split('\\').pop() +
      '<i class="fa-regular fa-trash-can ms-3" data-action="click->new-comment#removeAttach"></i>' +
      '</div>';
    this.attachmentsListTarget.insertAdjacentHTML('beforeend', html);
  }

  removeAttach(event) {
    document
      .querySelectorAll(`[data-id="${event.target.closest('.attachment-name').dataset.id}"]`)
      .forEach((element) => {
        element.remove();
      });
  }
}
