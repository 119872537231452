import { Controller } from '@hotwired/stimulus';
import { patch } from '@rails/request.js';

export default class extends Controller {
  static targets = ['commentsTab'];
  static values = {
    coverId: String
  };

  connect() {
    this.commentsTabTarget.addEventListener('shown.bs.tab', () => {
      patch(`/covers/${this.coverIdValue}/comments/read`, { responseKind: 'turbo-stream' });
      const badge = this.commentsTabTarget.querySelector('.badge');

      if (badge) {
        badge.remove();
      }
    });
  }

  async unreadComment() {
    const response = await patch(`/covers/${this.coverIdValue}/comments/mark_unread`);
    if (response.ok) {
      const body = await response.text;

      let badge = this.commentsTabTarget.querySelector('.badge');

      if (!badge) {
        badge = document.createElement('div');
        badge.classList.add('badge');
        badge.classList.add('rounded-pill');
        badge.classList.add('bg-danger');
        this.commentsTabTarget.append(badge);
      }

      badge.innerHTML = JSON.parse(body).comment_count;
    }
  }
}
