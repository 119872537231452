import { Controller } from '@hotwired/stimulus';
import { Datepicker } from 'vanillajs-datepicker';

export default class extends Controller {
  static values = {
    level: String,
    maxDate: String,
    minDate: String,
    container: String
  };

  connect() {
    const options = {
      buttonClass: 'btn',
      format: 'yyyy-mm-dd',
      autohide: true,
      maxDate: this.maxDateValue,
      minDate: this.minDateValue,
      container: this.containerValue
    };

    if (this.levelValue === 'month') {
      options.format = 'yyyy-mm';
      options.pickLevel = 1;
    }

    if (this.levelValue === 'year') {
      options.format = 'yyyy';
      options.pickLevel = 2;
    }

    new Datepicker(this.element, options);

    this.element.addEventListener('changeDate', () => {
      this.element.classList.remove('is-invalid');
    });
  }
}
