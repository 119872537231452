import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import toggleElement from '../utils/toggle-element';

export default class extends Controller {
  static targets = ['modelDetails', 'modelContainer'];
  static values = {
    url: String,
    visible: Boolean,
    count: Number,
    modelName: String
  };

  visibleValueChanged() {
    toggleElement(this.modelContainerTarget, this.visibleValue);
  }

  updateVisibility(event) {
    this.visibleValue = event.target.value;

    if (event.target.value === 'true') {
      this.sendRequest();
    } else {
      this.modelDetailsTarget.innerHTML = '';
    }
  }

  sendRequest() {
    get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: { index: this.countValue, model_name: this.modelNameValue }
    });
    this.countValue += 1;
  }

  addModel() {
    this.sendRequest();
  }

  removeModel(event) {
    const modelNode = event.currentTarget.closest('.nested-model-node');
    const destroyModelInput = modelNode.querySelector('.destroy-nested-model');

    // if model is persisted, it will have the destroy hidden input
    if (destroyModelInput) {
      modelNode.classList.add('d-none');
      destroyModelInput.value = true;
    } else {
      modelNode.remove();
    }
  }
}
