import { Controller } from '@hotwired/stimulus';
import toggleElement from '../utils/toggle-element';

export default class extends Controller {
  static targets = ['popover'];

  togglePopover() {
    toggleElement(this.popoverTarget, this.popoverTarget.classList.contains('d-none'));
  }

  clickOutside(event) {
    if (this.element === event.target || this.element.contains(event.target)) {
      return;
    }

    // event.preventDefault()

    toggleElement(this.popoverTarget, false);
  }
}
