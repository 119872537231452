import { Controller } from '@hotwired/stimulus';
import { Alert } from 'bootstrap';

export default class extends Controller {
  connect() {
    const alert = Alert.getOrCreateInstance(this.element);
    setTimeout(() => {
      alert.close();
    }, 3000);
  }
}
