import { Controller } from '@hotwired/stimulus';
import numeral from 'numeral';

const PRICE_FORMAT = '0,0.00';

export default class extends Controller {
  connect() {
    this.element.addEventListener('blur', (event) => {
      event.target.value = numeral(event.target.value).format(PRICE_FORMAT);
    });
  }
}
