import { Controller } from '@hotwired/stimulus';
import toggleElement from '../utils/toggle-element';

export default class extends Controller {
  static targets = ['accountSelect', 'accountContainer', 'permissionsContainer'];
  static values = {
    role: String
  };

  roleValueChanged() {
    if (this.roleValue !== 'user') {
      this.accountSelectTarget.value = '';
    }

    if (this.roleValue !== 'gowgates') {
      const checkboxes = this.permissionsContainerTarget.querySelectorAll('input');

      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }

    toggleElement(this.accountContainerTarget, this.roleValue === 'user');
    toggleElement(this.permissionsContainerTarget, this.roleValue === 'gowgates');
  }

  updateRole(event) {
    const { value } = event.target;

    this.roleValue = value;
  }
}
