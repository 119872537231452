import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link', 'input'];

  connect() {
    this.inputTarget.addEventListener('changeDate', (event) => {
      this.linkTarget.href = `${this.linkTarget.href}?date=${event.target.value}`;
    });
  }
}
