import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['quoteContainer'];
  static values = {
    selected: String
  };

  toggleScheme(event) {
    document
      .querySelectorAll(`.scheme-input[data-id]:not([data-id="${event.target.dataset.id}"])`)
      .forEach((element) => {
        element.checked = false
      });
  }
}
