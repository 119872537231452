import { Controller } from '@hotwired/stimulus';
import { Tab } from 'bootstrap';

export default class extends Controller {
  connect() {
    // change to first tab when modal is closed
    const myModalEl = this.element;
    myModalEl.addEventListener('hidden.bs.modal', () => {
      const nextTab = new Tab(this.element.querySelector('.nav-link'));
      nextTab.show();
    });
  }

  showTab(event) {
    const tabName = event.currentTarget.dataset.tabName;

    const element = this.element.querySelector(`.nav-link[data-tab-name=${tabName}]`);

    if (element) {
      const nextTab = new Tab(element);
      nextTab.show();
    }
  }
}
