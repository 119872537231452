import { Controller } from '@hotwired/stimulus';
import toggleElement from '../utils/toggle-element';

export default class extends Controller {
  static targets = ['headerCheckbox', 'rowCheckbox', 'form', 'ids', 'formAction'];
  static values = {
    selected: Array
  };

  selectedValueChanged() {
    toggleElement(this.formTarget, this.selectedValue.length > 0);
    this.idsTarget.value = this.selectedValue;

    if (this.selectedValue.length == 0) {
      this.headerCheckboxTarget.checked = false;
      this.headerCheckboxTarget.indeterminate = false;
    } else if (this.selectedValue.length == this.rowCheckboxTargets.length) {
      this.headerCheckboxTarget.checked = true;
      this.headerCheckboxTarget.indeterminate = false;
    } else {
      this.headerCheckboxTarget.checked = false;
      this.headerCheckboxTarget.indeterminate = true;
    }

    this.rowCheckboxTargets.forEach((rowCheckbox) => {
      rowCheckbox.checked = this.selectedValue.indexOf(rowCheckbox.value) >= 0;
    });
  }

  toggleRow(event) {
    if (event.target.checked) {
      this.selectedValue = [...this.selectedValue, event.target.value];
    } else {
      this.selectedValue = this.selectedValue.filter((value) => value != event.target.value);
    }
  }

  toggleHeader(event) {
    if (event.target.checked) {
      this.selectedValue = this.rowCheckboxTargets.map((rowCheckbox) => rowCheckbox.value);
    } else {
      this.selectedValue = [];
    }
  }

  submit(event) {
    event.preventDefault();
    this.formActionTarget.value = event.target.dataset.value;
    this.formTarget.submit();
  }
}
