import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['assetIdElement', 'modalContent'];
  static values = {
    coverId: Number
  };

  async getCovers() {
    if (!this.assetIdElementTarget.value) {
      return;
    }

    const response = await get(
      `/api/covers/${this.coverIdValue}/duplicates?asset_id=${this.assetIdElementTarget.value}`
    );
    if (response.ok) {
      response.text.then((data) => {
        const duplicates = JSON.parse(data);

        if (duplicates.length > 0) {
          this.modalContentTarget.innerHTML = duplicates
            .map((duplicate) => {
              return (
                '<tr>' +
                `<td><a href="/covers/${duplicate.id}" target="_blank">${duplicate.product_id}<a></td>` +
                `<td>${duplicate.declared_at}</td>` +
                `<td>${duplicate.name}</td>` +
                `<td>${duplicate.event}</td>` +
                `<td>${duplicate.status}</td>` +
                `<td class="text-end"><a href="/covers/${this.coverIdValue}/import_policy_data/${duplicate.id}" class="btn btn-primary">Import</a></td>` +
                '</tr>'
              );
            })
            .join('');

          const modal = new Modal('#duplicate-policy-modal', {});
          modal.show();
        }
      });
    }
  }

  connect() {
    this.getCovers();
  }

  check() {
    this.getCovers();
  }
}
